vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Eliminación de verrugas</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="Elimina tus verrugas" src="../../assets/verruga cuello.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            Elimina tus verrugas con <span class="p-text-bold">Plasma Pen</span>. Si padeces una verruga, en
                            Centro Médico Integral, ofrecemos un tratamiento seguro y efectivo que eliminará la afección y
                            restaurará la piel suave e impecable.
                        </p>
                        <p class="font-bold">Plasma Pen</p>
                        <p>
                            Se trata de un tratamiento que inicia con una primera evaluación del tipo de verrugas o lunares
                            en consulta, en la que se explica cómo a través de una pluma de plasma se produce una quemadura
                            controlada que eliminará por completo las <span class="p-text-bold">verrugas</span> o <span
                                class="p-text-bold">lunares</span> indeseados. Se puede aplicar un <span
                                class="p-text-bold">anestésico</span> antes de la extracción, pero no siempre es necesario,
                            ya que hay una mínima molestia durante el tratamiento. La piel sana alrededor de su verruga y no
                            se dañará.
                        </p>
                        <p class="font-bold">¿Después del tratamiento?</p>
                        <p>
                            La piel tratada puede estar <span class="p-text-bold">rosada</span> y <span
                                class="p-text-bold">sensible</span> durante unos días. En algunos casos, también puede haber
                            algunas <span class="p-text-bold">ampollas</span> y es posible que deba mantenerlo cubierto. Una
                            vez que la verruga ha sido tratada con éxito, se secará naturalmente y se caerá como lo haría
                            cualquier otra piel muerta.
                        </p>

                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RevitalizacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}
</style>